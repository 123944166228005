import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GlobalStateService {
  constructor(private router: Router, private route: ActivatedRoute) {}
  defaultTransferTypeIcon = '/assets/Icons/transfer_icons/minibus.svg';
  get TransferTypeFromCache() {
    return localStorage.getItem('transferTypes')
      ? JSON.parse(localStorage.getItem('transferTypes'))
      : null;
  }

  public reloadCurrentRoute() {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // let getBeforeTokenClearedUrl = localStorage.getItem(
    //   'beforeTokenClearedUrl'
    // );
    // if (!getBeforeTokenClearedUrl) {
    //   getBeforeTokenClearedUrl = this.router.url;
    // }
    // const currentUrl = getBeforeTokenClearedUrl + '?';
    // this.router.navigateByUrl(currentUrl).then(() => {
    //   this.router.navigated = false;
    //   this.router.navigate([getBeforeTokenClearedUrl]);
    // });
    // localStorage.removeItem('beforeTokenClearedUrl');
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const currentUrl = this.router.url + '?';
    this.router.navigateByUrl(currentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate([this.router.url]);
    });
  }

  getTransferTypeIconFromType(transferType) {
    let transferTypeIcon = this.defaultTransferTypeIcon;
    if (this.TransferTypeFromCache) {
      transferTypeIcon = this.TransferTypeFromCache.filter(typeItem => {
        return typeItem._id == transferType;
      })[0].icon;
    }
    return `/assets/Icons/transfer_icons/${transferTypeIcon}`;
  }

  public getTransferTypeNameFromType(transferType) {
    let transferTypeName = '';
    if (this.TransferTypeFromCache) {
      transferTypeName = this.TransferTypeFromCache.filter(typeItem => {
        return typeItem._id == transferType;
      })[0].name;
    }
    return transferTypeName;
  }

  public hideChatlyn(isShow) {
    const targetRoutes = [
      '/v2/extras/',
      '/v2/transfers/',
      '/v2/details/',
      '/v2/payment/',
    ];
    setTimeout(() => {
      const getBubbleHolder = document.getElementsByClassName(
        'woot--bubble-holder'
      );
      const getResolution = window.innerWidth;
      const currentUrl = window.location.pathname;
      if (
        getBubbleHolder.length > 0 &&
        getResolution < 576 &&
        this.doesUrlStartWith(currentUrl, targetRoutes)
      ) {
        if (isShow) {
          getBubbleHolder[0].classList.remove('d-none');
        } else {
          getBubbleHolder[0].classList.add('d-none');
        }
      } else {
        getBubbleHolder.length > 0
          ? getBubbleHolder[0].classList.remove('d-none')
          : '';
      }
    }, 200);
  }

  doesUrlStartWith(url, targetRoutes) {
    return targetRoutes.some(prefix => {
      return url.startsWith(prefix);
    });
  }
}
